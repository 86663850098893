@import 'Colours.scss';

.footer {
  background-color: $paysimply-black-accent;

  p {
    color: white;
  }

  a, button {
    color: #b9b9b9;
    margin-right: 25px;
    font-weight: 500;
    font-size: 1rem;
    padding: 2px;

    &:focus-visible {
      outline: 1px solid white !important;
      border-radius: 2px;
    }
    
    &:hover {
      color: white !important;
      text-decoration: none !important;
    }
  }

  .footerContentContainer {
    max-width: 1570px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 1rem 10px;
    @media only screen and (max-width: 816.9px) {
      flex-direction: column;
      align-items: flex-start;
    }

  }

  .disclaimerContainer {
    & > p {
      font-weight: 300;
      margin-bottom: 0;
      font-size: 0.7em;
      opacity: 0.8;
      line-height: 1.5em;
      text-align: right;

      @media only screen and (max-width: 816.9px) {
        text-align: left;
      }
    }

    .psiLink {
      margin-right: 0;
      font-size: inherit;
      color: white;
      opacity: inherit;
      padding: 0;
      &:hover {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 816.9px) {
      margin-top: 10px;
    }
  }
}
